<template lang="pug">
.organization-view 
  div( class="w-full px-8 xl:px-0 xl:w-8/12 2xl:w-1/2 mx-auto" )
    .d-flex.justify-between.align-center
      v-breadcrumbs(
        class="ml-0 pl-0"
        :items="breadcrumbs"
      )

    v-tabs.shadow.rounded-tr-lg.rounded-tl-lg.overflow-hidden( 
      grow 
      v-model="tab" 
    )
      v-tab( style="flex: 1 1 100%" ) Organization 
      v-tab( style="flex: 1 1 100%" ) Team 

      v-tabs-items( v-model="tab" )
        v-tab-item.pa-8.border-t
          template( v-if="!isAggregatesPending" )
            .d-flex.justify-between.gap-16.border-b
              div( class="flex-1")
                .d-flex.justify-between.py-12
                  div
                    h2.text-red-800  {{ aggregates.Expenses.sum.formatted }}
                    .font-semibold.text-gray-500.uppercase.text-xs.pt-2 Sum of Expenses

              v-divider( vertical )

              div( class="flex-1")
                .d-flex.justify-between.py-12
                  div
                    h2.text-red-800 {{ aggregates.Expenses.count }}
                    .font-semibold.text-gray-500.uppercase.text-xs.pt-2 Number of Expenses

            .d-flex.justify-between.gap-16
              div( class="flex-1")
                .d-flex.justify-between.py-12
                  div
                    h2.text-green-700 {{ aggregates.Receipts.sum.formatted }}
                    .font-semibold.text-gray-500.uppercase.text-xs.pt-2 Receipts Total

              v-divider( vertical )

              div( class="flex-1")
                .d-flex.justify-between.py-12
                  div
                    h2.text-green-700 {{ aggregates.Receipts.count }}
                    .font-semibold.text-gray-500.uppercase.text-xs.pt-2 Expenses Total
        v-tab-item.pa-8.border-t
          v-data-table.border(
            :items="users"
            :loading="isPending"
            :headers="headers"
            :server-items-length="!isPending && pagination ? pagination.total : 0"
            :options.sync="options"
            no-data-text="No users found"
          )
            template(
              v-slot:item.lastLoggedInAt="{ item: expense }"
            ) {{ $day(expense.lastLoggedInAt).format('MMM Do, YYYY') }}

</template>

<script>
import { computed, ref } from '@vue/composition-api'
import useApiGet from '@/api/useApiGet'
import useAggregates from '@/api/useAggregates'
import dataTableHeaders from '@/helpers/dataTableHeaders'
import useDataTableFind from '@/helpers/useDataTableFind'

export default {
  name: 'OrganizationView',

  setup(props, { root: { $store, $route, $FeathersVuex } }) {
    const { id } = $route.params;
    const tab = ref('');
    const { group } = $store.getters['auth/user'];
    const { User } = $FeathersVuex.api;

    const { item: user } = useApiGet({
      model: User,
      id,
      params: { 
        query: {
          $eager: '[organization]'
        } 
      }
    })

    const { headers, filterableColumns } = dataTableHeaders(
      [
        {
          text: 'FirstName',
          value: 'firstName',
          sortable: false
        },
        {
          text: 'LastName',
          value: 'lastName',
          sortable: false
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false
        },
        {
          text: 'Last Login',
          value: 'lastLoggedInAt',
          sortable: false
        }
      ], 
      []
    );
    
    const selectedOrgId = computed(() => user.value?.organization.id);
    const { aggregates, isPending: isAggregatesPending } = useAggregates({
      organizationId: selectedOrgId,
      aggregateModels: [
        { type: 'sum', col: 'amount', service: 'Expenses' },
        { type: 'count', col: 'amount', service: 'Expenses' },
        { type: 'sum', col: 'amount', service: 'Receipts' },
        { type: 'count', col: 'amount', service: 'Receipts' }
      ]
    })

    const filter = computed(() => {
      const filter = {
        $select: ['firstName', 'lastName', 'email', 'lastLoggedInAt'],
        organizationId: selectedOrgId.value,
        roleId: {
          $in: [6,7]
        }
      }

      return filter;
    })

    const {
      items: users,
      options,
      pagination,
      isPending
    } = useDataTableFind(
      'users',
      filterableColumns,
      filter,
      { sortBy: ['firstName'] }
    )

    const breadcrumbs = computed(() => {
      const to = group === 'organization' 
        ? '/organizationAdmins'
        : '/committeeAdmins';

      return [{
        text: 'Organizations',
        link: true,
        disabled: false,
        exact: true,
        to
      },
      {
        text: user.value?.email,
        disabled: true
      }]
    })

    return { 
      breadcrumbs,
      tab,
      id,
      headers,

      options,
      users,
      isPending,
      pagination,

      aggregates,
      isAggregatesPending
    }
  }
}
</script>